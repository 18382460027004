.header-container {
  display: flex;
  justify-content: space-between;
  gap: 20%;
  align-items: center;
  padding: 10px 25px;
  overflow: hidden;
  div {
    max-width: 200px;
    img {
      width: 100%;
    }
  }
}
