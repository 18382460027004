.intro-container {
  @include centerChilds;
  @include fillHeight;
  flex-direction: column;
  padding-inline: 20px;
  margin-inline: auto;

  img {
    width: 100%;
    max-width: 1200px;
    &:nth-child(2) {
      margin-top: 40px;
      min-width: 100px;
      width: 10%;

      cursor: pointer;
    }
  }
}
