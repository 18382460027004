@mixin ImageAppear {
  transition: transform 0.2s ease-out 0.15s, opacity 0.2s ease-out 0.15s;
  transform: scale(0);
  opacity: 0;

  animation: appearUp 0.75s ease-in;
  animation-fill-mode: none;
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}

.game-container {
  $space: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: $space;
  @include MdDesign;
  @include fillHeight;
  .model-container {
    flex-basis: 35%;
    @include centerChilds;
    img {
      @include ModelImage;
    }
  }

  .dropped-images-container {
    transform: rotate(-25deg);
    @include centerChilds;
    flex-basis: 40%;
    flex-flow: row wrap;
    .bubble {
      flex-basis: 50%;
      transform: rotate(25deg);
      img {
        @include ImageAppear;
      }
      &:nth-child(1) {
        margin-top: auto;
        text-align: end;
        img {
          width: clamp(50px, 100%, 200px);
        }
      }
      &:nth-child(2) {
        img {
          width: clamp(50px, 100%, 330px);
          animation-duration: 1s;
        }
      }
      &:nth-child(3) {
        img {
          width: clamp(50px, 100%, 600px);
          animation-duration: 1s;
        }
      }
      &:nth-child(4) {
        margin-bottom: auto;
        text-align: start;
        img {
          width: clamp(50px, 100%, 260px);
        }
      }
    }
  }

  .syringe-container {
    @include centerChilds;
    flex-direction: column;
    flex-basis: 20%;
    position: relative;
    bottom: 125px;
    @include md {
      bottom: 0;
    }

    img {
      width: 100%;
      max-width: 350px;
      @include ImageAppear;
      animation-name: appearLeft;

      &.dragging {
        visibility: hidden;
      }

      &.hint {
        max-width: 50%;
        margin-inline-start: auto;
        margin-bottom: auto;
      }
    }
  }
}

@keyframes appearUp {
  from {
    opacity: 0;
    transform: translateY(120%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearLeft {
  from {
    opacity: 0;
    transform: translateX(120%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
