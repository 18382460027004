@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");
// @import "primereact/resources/themes/lara-light-indigo/theme.css";
// @import "primeicons/primeicons.css";
// @import "primereact/resources/primereact.min.css";
@import "./_shared";
@import "./_header";
@import "./_game";
@import "./_intro";
@import "./_outro";
@import "./_login";
@import "./_loader";
@import "./_dashboard";
