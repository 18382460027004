.outro-container {
  @include fillHeight;
  margin-inline: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 5%;
  padding-inline: 50px;
  @include MdDesign;
  div {
    flex-grow: 1;
    flex-basis: 60%;

    img {
      width: 100%;
      max-width: 900px;
    }

    &:first-child {
      display: flex;
      justify-content: flex-end;
      flex-basis: 40%;
      img {
        @include ModelImage;
      }
    }

    &.rights-container {
      max-width: 1200px;
      padding-top: $header-height;
      flex-direction: column;
      font-size: 2rem;
      color: $white-color;
      @include fillHeight;
      justify-content: space-around;
      font-weight: lighter;
      margin-inline-end: auto;
      word-break: break-all;
      a {
        color: $white-color;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
