.dashboard-container {
  padding-block: 50px;
  h2 {
    padding: 30px 10px;
    text-align: center;
    font-size: 3rem;
    color: $burble-color;
  }
  .empty-message {
    text-align: center;
    margin-top: 25px;
  }
  table {
    margin-inline: auto;

    $cellPadding: 20px;
    thead tr {
      background: $white-color;
      th {
        padding: $cellPadding;
        color: $primary-color;
      }
    }
    td {
      text-align: center;
      padding: $cellPadding;
      border-bottom: 1px solid $burble-color;
    }
  }
}
