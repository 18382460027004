.login-container {
  min-height: 80dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 25px;
    width: clamp(150px, 100%, 700px);

    input,
    button {
      font-size: 1.2rem;
      border-radius: 10px;
      border: 2px solid $burble-color;
      padding: 15px 10px;
      transition: all 0.5s ease-in-out;
    }
    input {
      width: 100%;
      outline: none;
    }
    button {
      color: $white-color;
      background-color: $burble-color;
      width: clamp(100px, 50%, 300px);
      cursor: pointer;
      &:hover {
        background-color: $white-color;
        color: $burble-color;
      }
    }
  }
}
